import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home-one" */ "../views/HomeOne.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/product/:kategori_produk/merk-produk/:merk_produk",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product.vue"),
  },
  {
    path: "/product-category",
    name: "ProductCategory",
    component: () =>
      import(
        /* webpackChunkName: "productcategory" */ "../views/ProductCategory.vue"
      ),
  },
  {
    path: "/promo-details/:id_banner",
    name: "BlogDetails",
    component: () =>
      import(
        /* webpackChunkName: "promo-details" */ "../views/BlogDetails.vue"
      ),
  },
  {
    path: "/detil-product/:id_produk/jenis-produk/:jenis_produk/merk-produk/:merk_produk/nama-produk/:nama_produk",
    name: "DetilProduct",
    component: () =>
      import(
        /* webpackChunkName: "DetilProduct" */ "../views/DetilProduct.vue"
      ),
  },
  {
    path: "/filter-product/:merk_produk",
    name: "FilterProduct",
    component: () =>
      import(
        /* webpackChunkName: "ProductFilter" */ "../views/ProductFilter.vue"
      ),
  },
  {
    path: "/product-category/:kategori_produk",
    name: "ProductCategory",
    component: () =>
      import(
        /* webpackChunkName: "productcategory" */ "../views/ProductCategory.vue"
      ),
  },
  {
    path: "/search/:search",
    name: "SearchResultPage",
    component: () =>
      import(
        /* webpackChunkName: "SearchResultPage" */ "../views/SearchResultPage.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router;
